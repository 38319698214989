import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';
import React from 'react';

import Layout from '../shared/components/scaffolding/Layout';

const NotFoundPage = ({ location, pageContext }: SusPageProps) => (
  <PageProvider
    data={{
      pageType: '404',
      attributeMetaData: pageContext.attributeMetaData,
    }}>
    <Layout
      showBreadcrumbs={false}
      location={location}
      meta={{ title: 'Schrank und Stuhl - Zugriff verweigert' }}>
      <div className="flex flex-col items-center">
        <h1>Zugriff verweigert</h1>
      </div>
    </Layout>
  </PageProvider>
);

export default NotFoundPage;
